exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-github-workspace-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/github/workspace/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-github-workspace-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-github-workspace-content-pages-art-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/github/workspace/content/pages/art/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-github-workspace-content-pages-art-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-github-workspace-content-pages-jessie-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/github/workspace/content/pages/jessie/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-github-workspace-content-pages-jessie-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-github-workspace-content-pages-nature-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/github/workspace/content/pages/Nature/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-github-workspace-content-pages-nature-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-breakfast-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/github/workspace/content/projects/breakfast/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-breakfast-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-color-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/github/workspace/content/projects/color/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-color-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-landscapes-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/github/workspace/content/projects/Landscapes/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-landscapes-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-neon-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/github/workspace/content/projects/neon/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-neon-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-sunsets-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/github/workspace/content/projects/Sunsets/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-github-workspace-content-projects-sunsets-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

